import { Link } from "react-router-dom";
import $ from "jquery";
import './App.css';
import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import blog from "./blog.json"
import 'react-tabs/style/react-tabs.css';
import MetaTags from 'react-meta-tags';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { scrollToTop } from "./App";
function Blog() {

  useEffect(() => {
    
    scrollToTop();
    // getBlogapi()
  }, [])

  const links = ['All', 'Web Development', 'AR/VR', 'SAAS', 'Mobile App'];
  const [active, setActive] = useState(links[0]);
  // const [blog, setBlog] = useState([]);
  // const getBlogapi = () => {

  //   fetch("https://mocki.io/v1/5d886d51-1f1e-4dd0-bce4-df521520bd5f")
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data => {
  //       console.log("=========", data)
  //       setBlog(data)
  //     })
  // }


  $(function () {
		$(".loaded-blogs").slice(0, 3).show();
		$("body").on('click touchstart', '.load-mores', function (e) {
			e.preventDefault();
			$(".loaded-blogs:hidden").slice(0, 3).slideDown();
			if ($(".loaded-blogs:hidden").length === 0) {
				$(".load-mores").css('visibility', 'hidden');
			}
		
		});

    $(".Development .loaded-blog").slice(0, 3).show();
		$("body").on('click touchstart', '.Development .load-more', function (e) {
			e.preventDefault();
			$(".Development .loaded-blog:hidden").slice(0, 3).slideDown();
			if ($(".Development .loaded-blog:hidden").length === 0) {
				$(".Development .load-more").css('visibility', 'hidden');
			}
		
		});

    $(".SAAS .loaded-blog").slice(0, 3).show();
		$("body").on('click touchstart', '.SAAS .load-more', function (e) {
			e.preventDefault();
			$(".SAAS .loaded-blog:hidden").slice(0, 3).slideDown();
			if ($(".SAAS .loaded-blog:hidden").length === 0) {
				$(".SAAS .load-more").css('visibility', 'hidden');
			}
		
		});

    $(".AR-VR .loaded-blog").slice(0, 3).show();
		$("body").on('click touchstart', '.AR-VR .load-more', function (e) {
			e.preventDefault();
			$(".AR-VR .loaded-blog:hidden").slice(0, 3).slideDown();
			if ($(".AR-VR .loaded-blog:hidden").length === 0) {
				$(".AR-VR .load-more").css('visibility', 'hidden');
			}
		
		});

    $(".Mobile .loaded-blog").slice(0, 3).show();
		$("body").on('click touchstart', '.Mobile .load-more', function (e) {
			e.preventDefault();
			$(".Mobile .loaded-blog:hidden").slice(0, 3).slideDown();
			if ($(".Mobile .loaded-blog:hidden").length === 0) {
				$(".Mobile .load-more").css('visibility', 'hidden');
			}
		
		});
	});

  return (
    <div className="App Blog">
 <MetaTags>
            
            <meta name="description" content="Stay updated with the latest technology, web, and mobile technology. Explore the insightful blog by Arccus." />
            <meta name="keyword" content="Web & Mobile Technology Blog" />
            <meta property="og:title" content="Web & Mobile Technology Blog, Latest Technology Updates - Arccus" />
            <link rel="canonical" href="https://arccusinc.com/blog" />
            <title>Blog | Arccus Inc</title>
      </MetaTags>
      <section className="blog banner-sec ">
        <div className="container">
          <h1 className='text-center mb-4'>The  <span className='lastword'>Arccus Inc. Blog</span> </h1>
          <p className='text-center'>Welcome to the Arccus.Inc Blog Segment. Do you want to grasp the ocean of the digital world? Follow up on our updates to dig into Digital Engineering, Digital Marketing, Strategic Consulting, Mobile App Development, Technology Practices, Talent Solutions & lot more.</p>
        </div>
      </section>

      <section className="blog-tiles">
        <div className="container">
          <Tabs>
            <TabList>
              {links.map((link) => (
                <Tab href={`#${link}`}
                  className={` ${active === link && 'm-active'}`}
                  onClick={() => setActive(link)}>{link}
                </Tab>
              ))}
            </TabList>
            {blog?.map((Blog, ind) => {
              return (
                <TabPanel>
                  <h2 className="blogsection-head">{Blog.id}</h2>
                  <p className="blogsection-detail">{Blog.text}</p>
                  <div className="row blog-row">

                    {active === links[0]
                      ? blog?.map((b, index) => {
                        
                        return (
                          
                          <>
                            <h2 className="blogsection-head">{b.id}</h2>
                            <p className="blogsection-detail">{b.text}</p>
                            <div className={`row blog-row ${b.id}`}>
                            {index !== 0 && b?.Blog?.map((blog, i) => {
                              return (

                                <div className="col-md-4 col-sm-12 col-xs-12 loaded-blog">
                                <Link to={`/${blog?.bloglink?.replaceAll(" ", "-")}`} target="_blank">
                                  <div className="blog-content">
                                 
                                    <div className="top-content">
                                      <img src={blog.images} className="blog-image" alt={"blog"} />
                                      <div className="middle-content">
                                        <h5>{blog.title}</h5>
                                        <p>{blog.content}</p>

                                      </div>
                                    </div>
                                    <div>
                                      <span className="learn-more">Learn More <HiOutlineArrowRight className='right-aerrow' /></span>
                                      <ul className="bottom-content">
                                        <li className="author">{blog.author}</li>
                                        <li className="date">{blog.date}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </Link>
                                </div>


                              )

                            })}
                            {index !== 0 && 
                            <>
                            {b.Blog.length > 3 && (
                              <div className="col-sm-12 text-center">
                                <button className="quote w-auto m-auto mt-3 other-tab load-more ">View More</button>
                              </div>
                            )}
                            </>
                            }
                            </div>
                          </>

                        )
                      })
                      :  
                      <div className="row blog-row">


                      {Blog.Blog.map(blog => {
                        
                        return (
                          <div className="col-md-4 col-sm-12 col-xs-12 loaded-blogs">
                          {console.log("=============>",blog)}
                          <Link to={`/${blog?.bloglink?.replaceAll(" ", "-")}`} target="_blank">
                            <div className="blog-content" >
                          
                              <div className="top-content">
                                <img src={blog.images} className="blog-image" alt={"blog"} />
                                <div className="middle-content">
                                  <h5>{blog.title}</h5>
                                  <p>{blog.content}</p>

                                </div>
                              </div>
                              <div>
                                <span className="learn-more">Learn More <HiOutlineArrowRight className='right-aerrow' /></span>
                                <ul className="bottom-content">
                                  <li className="author">{blog.author}</li>
                                  <li className="date">{blog.date}</li>
                                </ul>
                              </div>
                            </div>
                          </Link>
                          </div>

                        )
                      })}
                      {Blog.Blog.length > 3 && (
                      <div className="col-sm-12 text-center"><button className="quote w-auto m-auto mt-3 other-tab load-mores ">View More</button></div>
                      )}
                      </div>
                      
                      }
                  </div>

                </TabPanel>
              )
            })}
          </Tabs>
        </div>
      </section>

    </div>
  );
}

export default Blog;

