// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import project from "./project.json";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




export default () => {
  useEffect(() => {
    // getProjectapi()
  }, [])

  // const [project, setProject] = useState([]);
  // const getProjectapi = () => {

  //   fetch("https://mocki.io/v1/d30fa078-1424-4704-a587-5b0ebaa59f6c")
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data => {
       
  //       setProject(data)
  //     })
  // }
  
  return (
   
    <Swiper breakpoints={{
      0: {
        slidesPerView: 1.1,
      },
      375:{
        slidesPerView:1.2,
      },
      450:{
        slidesPerView:1.4,
      },
      550:{
        slidesPerView:1.7,
      },
      639: {
        slidesPerView: 1.8,
      },
      865:{
        slidesPerView:2.5,
      },
      1000:{
        slidesPerView:3,
      },
      1200:{
        slidesPerView:4,
      },
      1700:{
        slidesPerView:4,
      }
    }}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={16}
      slidesPerView={4}
      navigation
    >
     {project?.map( item => {
        return(
      <SwiperSlide>
      {console.log(`url(${item.project})`)}
        <div className='project-slide' style={{backgroundImage:`url(${item.project})`}} loading="lazy" >
            <ul>
            {
                item?.device?.map(
                    projectType => {
                        return(
                            <li>{projectType}</li>
                        )
                    }
                )
            }
            </ul>
            <div className='project-detail' >
                <h3 className='project-name'>{item.name}</h3>
                <p className='project-tech'>{item.tech}</p>
            </div>
              <a className='project-detail-btn' href={item.link}>view more &#129138;</a>
        </div>
      </SwiperSlide>
      )})} 
      
    </Swiper>
  );
};