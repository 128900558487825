import { useNavigate } from "react-router-dom";
import "./App.css";
import React, { useState,useEffect } from "react";
import MaskGroup from "./assets/MaskGroup.jpg";
import job from "./job.json";
import Modal from 'react-bootstrap/Modal';
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";


function Career() {
  useEffect(() => {
    
    scrollToTop();
 
  }, [])

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showModelData, setShowModelData] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

// const [job, setJob] = useState([]);
//   const getCareerapi = () => {

//     fetch("https://mocki.io/v1/cc686d7a-4856-4939-b8d5-ffa8290fa5fc")
//       .then(response => {
//         return response.json()
//       })
//       .then(data => {
//         console.log("=========", data)
//         setJob(data)
//       })
//   }

  return (
    <div className="App Blog">
     <MetaTags>
            
            <meta name="description" content="Discover abundant job opportunities and join a leading app development company. Unlock exceptional growth prospects to reach new heights." />
            <meta name="keyword" content="Business Development Manager, WEB Designer, PHP Developer, IPHONE & ANDROID Developer, NET Developer, ONLINE Marketing, SOFTWARE Testing"/>
            <meta property="og:title" content="Arccus Inc Careers | Jobs Openings - Apply For Job Now!" />
            <link rel="canonical" href="https://arccusinc.com/career"/>
            <title>Careers | Arccus Inc</title>
      </MetaTags>
      <section className="blog banner-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h4>STOP PONDERING</h4>
              <h1>Grab the job you always wanted</h1>
              <p>
                We’re looking for dedicated, creative people to join us in
                making Arccus even better. Arccus was built on strong values,
                principles and a vision – to deliver high value results to our
                customers and to create relationships for everyone associated
                with us. We also want to be leaders with strength in developing
                software and business applications.
              </p>
            
            </div>
            <div className="col-md-6 mask-group">
              <img src={MaskGroup} alt={"MaskGroup"} />
            </div>
          </div>
        </div>
      </section>

      <section className="job-position">
        <div className="container">
          <h4>APPLY FOR</h4>
          <h2>
            Job Positions <span className="lastword">@ Arccus Inc.</span>
          </h2>
          <div className="main-job-position d-flex flex-wrap">
            {job?.map((job) => {
              return (
                <div className="job-position-data d-flex">
                  <div className="job-icon">
                    <img src={job.icon}  alt={"icon"} />
                  </div>
                  <div className="job-type">
                    <h5>{job.job}</h5>
                    <button className="description" onClick={()=> {handleShow()
                    setShowModelData(job)
                    }}>
                      READ JOB DESCRIPTION
                    </button>
                    <Modal show={show} onHide={handleClose} size="lg" backdrop="static"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered className="job-des-model">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <p>Arccus Inc. is looking for a {showModelData?.job} who should be ready to experience all areas of sales and can effectively convert leads into clients.</p>
                    <h3>Job Requirements:</h3>
                    <ul>
                    {showModelData?.modelContent.map( ms => { 
                       return(
                      <li>{ms}</li>
                      )})}
                    </ul>
                    </Modal.Body>
                  </Modal>
                  </div>
                  <div className="job-location">
                    <p>Location</p>
                    <p className="location-text">{job.Location}</p>
                  </div>
                  <div className="job-duration">
                    <p>Duration</p>
                    <p className="duration-text">{job.Duration}</p>
                  </div>
                  <div className="job-experince">
                    <p>Experience</p>
                    <p className="experince-text">{job.experince}</p>
                  </div>
                  <div className="job-apply-btn">
                    <button
                      className="quote"
                      onClick={() => {
                        navigate("/apply",{state:job})
                       
                      }}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

    
    </div>
  );
}

export default Career;
