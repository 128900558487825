
import './App.css';
import React, { useEffect } from "react";
import Faq from "react-faq-component";
import faq from './faq.json';
import faqmask from './assets/faq.png';
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";
const styles = {
    // bgColor: 'white',
    titleTextColor: "#808080",
    rowTitleColor: "#333",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
};
function FAQ() {
    useEffect(() => {
    
        scrollToTop();
     
      }, [])
  return (
    
         
    <div className="App Faq">
    <MetaTags>
            
            <meta name="description" content="Software development is a complex process, but we have the answers from our 30+ years of experience. Can’t find what you need here? Get in touch!" />
            <meta name="keyword" content="UX design, development, web design and web developer, web developer ui ux, web development services, web development company usa, it services, it consulting, it consulting companies in usa, SEO techniques,  digital marketing agencies" />
            <meta property="og:title" content="FAQ | Arccus Inc" />
            <link rel="canonical" href="https://arccusinc.com/faq" />
            <title>FAQ | Arccus Inc</title>
      </MetaTags>
     
     <section className='faq  banner-sec'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <h1 >Frequently Asked <span className='lastword'>Questions</span></h1>
                    <p>FAQs are a learnt response. People like them because they are short, easy to read and get to the point.</p>
                </div>
                <div className='col-md-6'>
                    <img src={faqmask} alt={"faqmask"} />
                </div>
            </div>
        </div>
     </section>

     <section className='App-Development-FAQ'> 
        <div className='container'>
            <h2 className='line-bottom'>App Development <span className='lastword'>FAQ</span></h2>
            <p>Software development is a complex process, but we have the answers from our 30+ years of experience. Can’t find what you need here? <Link to="ContactUs">Get in touch!</Link></p>
        
     

     <div className='faq-data'>
       
            <div className='faq-data-row row'>
            {faq?.map( f => {
                      return(
                <div className='col-md-6'>
                
                <Faq
                    data={f}
                    styles={styles}
                    config={config}
                />
                 
                </div>
            )})}
                
                
            </div>
            </div>
     </div>
     </section>

    </div>
    
    
  );
}

export default FAQ;

